.pricing-wrapper {
  .arrow-large {
    .line,
    .pointer {
      @apply transition-all;

      stroke-dasharray: 800;
      stroke-dashoffset: 800;
    }
  }

  > .show .arrow-large {
      .line {
        animation: animateDash 0.75s ease-in 0.5s forwards;
      }
      .pointer {
        animation: animateDash 0.75s ease-in 1.25s forwards;
      }
  }
}
@keyframes animateDash {
  from {
    stroke-dashoffset: 800;
  }
  to {
    stroke-dashoffset: 0;
  }
}
