.button {
  &-primary, &-secondary {
    @apply w-fit font-head text-default text-white-100 rounded-[5px] transition-all leading-tight font-bold;
  }

  &-primary {
    &.arrow {
      * {
        @apply transition-all;
      }

      svg {
        @apply rotate-180;
      }

      @apply flex items-center gap-[10px];

      &:hover {
        svg {
          @apply -translate-x-1;
        }
      }
    }
  }
}
