//
// Common: Fonts
//
// @author: lemone.com
//

$fontpath: "../fonts";

// // Font definitions

@font-face {
  font-family: "Jeko Medium";
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: url("#{$fontpath}/JekoMedium.woff2") format("woff2"),
    url("#{$fontpath}/JekoMedium.woff") format("woff");
}

@font-face {
  font-family: "Jeko Bold";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("#{$fontpath}/JekoBold.woff2") format("woff2"),
    url("#{$fontpath}/JekoBold.woff") format("woff");
}
