.menu {
  @apply transition-all;
  &.scrolled {
    @apply py-2;
  }


  .logo {
    width: 200px;
    @media screen and (max-width: 900px) {
      width: 130px;
    }
    @media screen and (max-width: 768px) {
      width: 120px;
    }
  }
  &-toggle {
    @apply h-6 flex flex-col justify-between cursor-pointer;

    .top,
    .middle,
    .bottom {
      @apply bg-green-100 rounded-sm;
      height: 3px;
    }

    .top,
    .middle {
      @apply w-10;
    }

    .bottom {
      @apply w-8;
    }

    .top {
      animation: TopBar 0.45s ease-out forwards;
    }

    .middle {
      animation: MiddleBar 0.45s ease-out forwards;
    }

    .bottom {
      animation: BottomBar 0.45s ease-out forwards;
      opacity: 1;
    }

    &.active {
      .top {
        animation: TopBarActive 0.45s ease-out forwards;
      }
      .middle {
        animation: MiddleBarActive 0.45s ease-out forwards;
      }
      .bottom {
        animation: BottomBarActive 0.45s ease-out forwards;
      }
    }
  }

  &-items {
    li a.active {
      @apply text-green-400;
    }
  }

  &-items-mobile {
    @media screen and (max-width: 768px) {
      @apply opacity-0;
    }
    &.active {
      @apply fixed flex md:hidden justify-between opacity-100 left-0 top-0 bg-green-800 z-40 w-screen;
      padding-top: 75px;
      height: 100vh;
      height: 100dvh;

      .items {
        a {
          font-size: 2rem;
        }
      }
      li {
        opacity: 0;
        &:nth-of-type(1) {
          animation: AnimMobile 0.5s 0.5s forwards;
        }
        &:nth-of-type(2) {
          animation: AnimMobile 0.5s 0.6s forwards;
        }
        &:nth-of-type(3) {
          animation: AnimMobile 0.5s 0.7s forwards;
        }
        &:nth-of-type(4) {
          animation: AnimMobile 0.5s 0.8s forwards;
        }
        &:nth-of-type(5) {
          animation: AnimMobile 0.5s 0.8s forwards;
        }
      }
      .cta {
        opacity: 0;
        animation: AnimMobile 0.5s 1s forwards;
        a,
        button {
          @apply justify-center;
          width: 50%;
        }
      }
    }
  }
  @keyframes AnimMobile {
    from {
      opacity: 0;
      transform: translateX(60vw);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  // Toggle
  @keyframes TopBarActive {
    0% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(0.6rem) rotate(55deg) scaleX(1.15);
    }
    60% {
      transform: translateY(0.6rem) rotate(40deg) scaleX(1.1);
    }
    80% {
      transform: translateY(0.6rem) rotate(55deg) scaleX(1.05);
    }
    85% {
      transform: translateY(0.6rem) rotate(42deg) scaleX(0.95);
    }
    95% {
      transform: translateY(0.6rem) rotate(43deg) scaXex(1);
    }
    100% {
      transform: translateY(0.6rem) rotate(45deg) scaleX(1);
    }
  }

  @keyframes MiddleBarActive {
    0% {
      transform: translateY(-0.05rem);
    }
    40% {
      transform: translateY(-0.05rem) rotate(-55deg) scaleX(1.15);
    }
    60% {
      transform: translateY(-0.05rem) rotate(-40deg) scaleX(1.1);
    }
    80% {
      transform: translateY(-0.05rem) rotate(-55deg) scaleX(1.05);
    }
    85% {
      transform: translateY(-0.05rem) rotate(-42deg) scaleX(0.95);
    }
    95% {
      transform: translateY(-0.05rem) rotate(-43deg) scaleX(1);
    }
    100% {
      transform: translateY(-0.05rem) rotate(-45deg) scaleX(1);
    }
  }

  @keyframes BottomBarActive {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(10rem);
    }
  }

  @keyframes TopBar {
    0% {
      transform: translateY(0.6rem) rotate(45deg);
    }
    40% {
      transform: translateY(-0.2rem) rotate(-4deg);
    }
    45% {
      transform: translateY(0.1rem) rotate(-2.5deg);
    }
    56% {
      transform: translateY(0rem) rotate(0deg) scaleX(1.2);
    }
    78% {
      transform: translateY(0rem) rotate(0deg) scaleX(0.9);
    }
    89% {
      transform: translateY(0rem) rotate(0deg) scaleX(1.05);
    }
    100% {
      transform: translateY(0rem) rotate(0deg) scaleX(1);
    }
  }

  @keyframes MiddleBar {
    0% {
      transform: translateY(-0.05rem) rotate(-45deg);
    }
    40% {
      transform: translateY(0.02rem) rotate(4deg);
    }
    45% {
      transform: translateY(0.1rem) rotate(-2.5deg);
    }
    56% {
      transform: translateY(0rem) rotate(0deg) scaleX(1.2);
    }
    78% {
      transform: translateY(0rem) rotate(0deg) scaleX(0.9);
    }
    89% {
      transform: translateY(0rem) rotate(0deg) scaleX(1.05);
    }
    100% {
      transform: translateY(0rem) rotate(0deg) scaleX(1);
    }
  }

  @keyframes BottomBar {
    0% {
      transform: scaleX(1) translateX(10rem);
      opacity: 0;
    }
    56% {
      transform: scaleX(1.2) translateX(0);
      opacity: 1;
    }
    78% {
      transform: scaleX(0.9) translateX(0);
      opacity: 1;
    }
    89% {
      transform: scaleX(1.05) translateX(0);
      opacity: 1;
    }
    100% {
      transform: scaleX(1) translateX(0);
      opacity: 1;
    }
  }
}
