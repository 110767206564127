.card {
  &-account {
    @apply p-8 lg:p-0 border border-solid rounded-xl border-green-500 lg:border-none w-full lg:w-1/3 self-stretch;
    img {
      box-shadow: 0 19px 30px 0 rgba(0, 0, 0, 0.08);
    }
    p {
      @apply font-head pt-4 md:pb-5 leading-7 text-ml flex self-stretch justify-center mb-auto;
    }
  }

  &-usp {
    @apply w-full md:w-1/3 bg-green-500 rounded-xl py-8 px-5 md:py-12 md:px-8 self-stretch;

    .content {
      @apply flex-grow;
    }
  }

  &-bot {
    @apply relative flex flex-col px-5 py-6 md:px-7 md:py-8 justify-center lg:items-center self-stretch w-full lg:w-96 bg-green-500 rounded-lg;

    .bot-label {
      @apply flex items-center text-green-800 rounded-2xl px-2 h-7 w-fit;
      span {
        @apply font-head leading-none;
      }
    }

    .content {
      @apply flex-grow;
    }
  }

  &-pricing {
    @apply relative flex flex-col bg-green-400 rounded-lg pt-9 px-8 pb-10 gap-7 w-fit text-green-900;
    max-width: 360px;
    .icon:not(.button-primary) {
      svg {
        @apply w-5 h-5;
      }
    }
    &::after {
      content: '';
      background: url('../images/price-bg.webp');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      width: 130px;
      height: 213px;
      bottom: 0;
      right: 1rem;
      z-index: -1;
    }
  }

  &-reviews {
    @apply flex shrink-0 snap-start flex-col items-center justify-center;

    .wrapper {
      @apply flex flex-col py-7 px-5 lg:pt-11 lg:pb-9 lg:px-7 justify-start items-center bg-green-900 rounded-xl flex-1;
    }

    .card-header {
      p {
        @apply pt-4;
      }
    }

    .card-footer {
      @apply flex w-full pt-6 mt-auto items-center gap-3;

      .img-wrapper {
        @apply relative;

        img {
          @apply rounded-full;

          height: 50px;
          width: 50px
        }
        .spark {
          right: -0.4rem;
          top: -0.4rem;
          padding: 4px 6px;
        }
      }
    }
    .info {
      @apply flex flex-col justify-center;

      p {
        @apply font-head text-base;
      }
      // p:first-of-type {
      //   @apply text-base font-head pb-1;

      //   transform: translateY(0.25rem)
      // }
      // p:last-of-type {
      //   @apply text-xs;

      //   transform: translateY(-0.25rem)
      // }
    }
  }
}
