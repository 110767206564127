.phone {
  // Text animation
  .text-wrapper {
    @screen lg {
      width: 50%;
      p {
        padding-right: 1.25rem;
      }
    }
  }

  // Phone image
  .phone-image-container {
    * {
      @apply select-none pointer-events-none will-change-transform;
    }

    .sparx-profit {
      transition: 0.7s ease-out all;
    }
    .phone {
      transition: 0.6s ease-out all;
    }
    .bot-trading {
      @apply rounded-3xl;
      transition: 0.5s ease-out all;
    }

    .phone,
    .sparx-profit,
    .bot-trading {
      @apply h-auto;
    }
    .sparx-profit {
      top: 12.5%;
      left: 5%;
      width: 30%;
    }
    .bot-trading {
      @apply h-auto;
      width: 35%;
      bottom: 9.5%;
      right: 7.5%;
      box-shadow: 0 2px 50px rgb(0, 0, 0, 0.4);
    }
  }
}
