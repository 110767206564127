.phone-wrapper {
  .phone-image-container {
    @apply opacity-0;
    &.show {
      @apply animate-right-fade-in;
    }
  }

  .text-wrapper {
    h2,
    p {
      @apply opacity-0;
      transform: translateY(5rem);
    }

    button {
      @apply opacity-0;
    }

    &.show {
      h2 {
        @apply animate-first-fade-in;
      }

      p {
        @apply animate-second-fade-in;
      }

      button {
        @apply opacity-0 animate-button-pop;
      }
    }
  }
}
