.ai-bots {
  .text-wrapper {
    @apply pl-8;
    @media screen and (min-width: 1100px) {
      padding-left: max(5%, 5rem);
    }
    @media screen and (max-width: 900px) {
      @apply pl-0;
    }
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    .ai-bots-image-container,
    .text-wrapper {
      @apply w-full;
    }
    .text-wrapper {
      @apply pt-7;
    }
  }
}
