.footer {
  .logo {
    width: 150px;
    @media screen and (max-width: 900px) {
      width: 130px;
    }
    @media screen and (max-width: 768px) {
      @apply hidden;
      width: 120px;
    }
  }
}
