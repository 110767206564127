.review-slider {
  ul {
    @apply ml-0;
    li {
      @apply w-full;
      @screen sm {
        width: 330px;
      }
      &:first-child {
        @apply pl-0;
      }
      &:last-child {
        @apply pr-0;
      }
    }
  }
}
