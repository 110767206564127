.about-wrapper {
  .about-image {
    @apply opacity-0;
    &.show {
      @apply animate-right-fade-in;
    }
  }

  .about-text {
    h2,
    p,
    h5,
    .socials{
      @apply opacity-0;
    }

    &.show {
        h2 {
          @apply animate-first-fade-in;
        }

        p {
          @apply animate-second-fade-in;
        }

        h5 {
          @apply animate-third-fade-in;
        }

        .socials {
          @apply animate-button-pop;

          > a svg {
            @apply w-6 h-auto;
          }
        }
    }
  }
}
