.privacy,
.disclaimer {
  .table {
    @apply border border-green-100 p-4 bg-green-500 rounded-[6px];

    display: block;
    @media screen and (max-width: 768px) {
      overflow-x: scroll;
    }
    table {
      min-width: 700px;
      * {
        @apply border-green-100;
      }
      thead {
        border-bottom: 1px solid;
      }

      td,
      th {
        @apply p-2;
        &:not(:last-child) {
          border-right: 1px solid;
        }
      }

      tr:not(:last-child) {
        border-bottom: 1px solid;
      }
    }
  }

}
