.button-primary {
  @apply py-4 md:py-5 px-7 bg-orange-500 hover:bg-orange-400;
  &.icon {
    * {
      @apply transition-all;
    }
    @apply flex items-center;

    gap: 10px;

    &:hover {
      svg {
        @apply translate-x-1;
      }
    }
  }
}
