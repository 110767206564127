.ai-wrapper {
  .text-wrapper {
    h2,
    p,
    .button-primary {
      @apply opacity-0;
    }
    &.show {
      h2 {
        @apply animate-first-fade-in;
      }

      p {
        @apply animate-second-fade-in;
      }

      .button-primary {
        @apply animate-button-pop;
      }
    }
  }

  .ai-bots-image-container {
    svg {
      .sparx-icon,
      .btc-icon {
        opacity: 0;
      }
      .background {
        opacity: 0;
      }
    }
    &.show {
      .sparx-icon,
      .btc-icon {
        opacity: 0;
        animation: animateOpacity 0.25s ease-in 2.5s forwards;
      }

      .sparx-line,
      .btc-line {
        stroke-dasharray: 800;
        stroke-dashoffset: 800;
        animation: animateDash 3s ease-in forwards;
      }
      .background {
        opacity: 0;
        animation: animateBackground 1.75s ease 1.5s forwards;
      }
    }
  }

  @keyframes animateDash {
    to {
      stroke-dashoffset: 0;
    }
  }
  @keyframes animateShadow {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }
  }
  @keyframes animateOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100;
    }
  }

  @keyframes animateBackground {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.069;
    }
  }
}
