.pricing {
  ul {
    @apply w-full lg:w-1/2 flex flex-col gap-5;
    li {
      @apply flex gap-4;
      svg {
        @apply mt-1;
        min-width: 20px;
        min-height: 20px;
      }
      p {
        @apply text-base font-head;
      }
    }
  }
  .arrow-large {
    @apply hidden md:flex self-center md:self-auto;
  }
  .button-primary {
    @apply bg-green-900 hover:bg-green-600 text-green-400 z-10;
  }
}
