body {
  @apply overflow-x-hidden;

  &.mobile-active {
    @apply overflow-hidden;
  }
}

html {
  @apply leading-loose text-green-100 scroll-smooth antialiased;
}

h2,
h3,
h4,
h5 {
  @apply font-head text-green-100;
  span {
    @apply text-green-400;
  }
}

h2 {
  @apply text-3xl flex;
  line-height: clamp(2.25rem, 1.6681rem + 2.4828vw, 3.375rem);; // 54 / 36
  &.hero {
    @apply text-hero;

    text-wrap: balance;
    line-height: clamp(3.125rem, 2.6724rem + 1.931vw, 4rem); // 64 / 50;
  }
}

h3 {
  @apply text-2xl;
  line-height: clamp(2rem, 1.5474rem + 1.931vw, 2.875rem); // 24 / 32
}

h4 {
  @apply text-xl;
  line-height: clamp(1.5rem, 1.2414rem + 1.1034vw, 2rem); // 32 / 24
}

h5 {
  @apply text-lg leading-none;
}

p {
  @apply text-default leading-7;
  &.hero {
    @apply text-base;
    line-height: clamp(1.75rem, 1.6853rem + 0.2759vw, 1.875rem);
  }
}

ul,
ol,
li {
  @apply list-none;
}

a:where(:not([class^="button"])) {
  @apply inline-flex gap-1 items-center text-green-100 hover:text-green-300;
}

svg {
  @apply no-underline;
}

// Sparx Logo
.sparx-logo {
  @apply opacity-0 animate-reverse-fade-in;
}

// Strokes
.stroke {
  @apply relative;
  z-index: -1;
  &::after {
    @apply bg-green-600;
    content: "";
    position: absolute;
    height: 25rem;
    width: 150%;
    transform: rotate(-17.5deg);
    left: -20%;
  }
  &.top {
    &::after {
      top: -52.5rem;
      @screen md {
        top: -12rem;
      }
    }
  }
  &.bottom {
    &::after {
      top: -4rem;
      height: 35rem;
      left: -25%;
      @screen md {
      top: 3rem;
      }
    }
  }
}

span[data-hs-anchor="true"] {
  display: block;
  position: relative;
  visibility: hidden;
  top: -16px;
 }

// Remove animations according to user preference
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition-delay: 0ms !important;
  }
}
