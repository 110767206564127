$imageWidth: 50%;
@media screen and (max-width: 1200px) {
  $imageWidth: 80%;
}
$textWidth: calc(100% - $imageWidth);

.about {
  &-text {
    padding-right: max(5%, 5rem);
    width: $textWidth;
  }
  &-image {
    @apply h-full;
    width: $imageWidth;
    border-radius: 10px;
    background-image: url('../images/sparx-office.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .socials {
    a {
      @apply w-6 h-6;

      svg {
        @apply text-orange-600 hover:text-orange-500 transition-all;
      }
    }
  }
  @media screen and (max-width: 900px) {
      flex-direction: column;
      align-items: start;
      &-text,
      &-image {
        @apply w-full;
      }
      &-text {
        @apply pr-0;
      }
      &-image {
        @apply mt-5;
        height: 17rem;
      }
  }
}
