main {
  padding-top: 84px;

  @screen md {
    @apply pt-0;
  }
}

[class^="innerwrapper"] {
  @apply w-full flex items-center px-4 md:px-6;
  @screen xsm {
    width: 1140px;
  }

  &.ai-bots {
    @apply pb-16 md:pb-32;
  }

  &.phone {
    @apply pb-16;
  }
  &.pricing {
    @apply flex-col items-start pb-16 md:pb-32;
  }
}
.innerwrapper-about {
  @apply pb-16 md:pb-44;
  align-items: normal;
}

.innerwrapper-bots {
  @apply pb-16 md:pb-32;
}

.innerwrapper-usps {
  @apply pb-16 md:pb-32;
}

.innerwrapper-accounts {
  @apply pb-16 md:pb-32;
}

.innerwrapper-reviews {
  @apply mx-auto pb-16 md:pb-32;
  align-items: normal;

  @screen xsm {
    width: 100%;
  }
  @media screen and (min-width: 1236px) {
    width: 1202px;
  }
}
