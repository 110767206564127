@tailwind base;
@tailwind components;
@tailwind utilities;

@layer vendor;
@import "base";
@import "components";
@import "animations";

@import "npm:@glidejs/glide/dist/css/glide.core.min.css" layer(vendor);
