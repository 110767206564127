.button-secondary {
  @apply p-6 bg-transparent border-2 border-solid border-orange-600 transition-all h-fit;
  svg {
    @apply transition-all text-orange-600;
  }

  &.prev {
    svg {
      @apply rotate-180;
    }
  }

  &.prev,
  &.next {
    @apply p-0 flex items-center justify-center;
    height: 60px;
    width: 60px;
  }

  &:hover:not(.disabled) {
    @apply hover:bg-orange-600;
    svg {
      @apply text-white-100 translate-x-1;
    }
    &.prev {
      svg {
        @apply -translate-x-1 rotate-180;
      }
    }
  }

  &.disabled {
    @apply border-green-200;
    svg {
      @apply text-green-200;
    }
  }

  &.icon {
    @apply flex items-center gap-[10px] py-4 px-7;
    svg {
      @apply text-white-100;
    }
    * {
      @apply transition-all;
    }

    &:hover {
      svg {
        @apply translate-x-1;
      }
    }
  }
}
